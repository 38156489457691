/* eslint-disable react/display-name */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled, { css } from 'styled-components';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import { List, ListItem } from 'src/components/atoms/List';
import { Grid } from 'src/components/molecules/Grid';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  ${({ alternative, theme, enterprise }) =>
    alternative &&
    css`
      border-radius: 0;
      border-top: 0.5rem solid ${enterprise ? theme.primaryColor : '#aba3ef'};
    `}
`;

const Header = styled.div`
  padding: 1.5rem 1.5rem 0;
  color: #6d708b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 13.5rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.primaryColor};
`;

const Content = styled.div`
  flex: 1;
  padding: 1.5rem 1.5rem 0;
  color: #6d708b;
`;

const Footer = styled.div`
  padding: 1rem 1rem 0;
`;

export default function LegacySection({ heading, callouts, customData, ctas }) {
  const flags = JSON.parse(customData || '{}').flags;
  return (
    <Section {...flags}>
      <SectionHeader>
        <h2>{heading}</h2>
      </SectionHeader>
      <Grid columns="1fr 1fr 1fr">
        {callouts.map((callout, i) => {
          const { heading, preHeading, preContentNode, contentNode, ctas } = callout;
          const calloutFlags = JSON.parse(callout.customData)?.flags || {};
          return (
            <Container key={i} {...{ alternative: flags.alternative, ...calloutFlags }}>
              <Header>
                <Title>{heading}</Title>
                <Subtitle>{preHeading}</Subtitle>
                <div dangerouslySetInnerHTML={{ __html: preContentNode.childMarkdownRemark.html }} />
              </Header>
              <Content>
                <MDXProvider
                  components={{
                    Button,
                    ul: List,
                    li: ({ children }) => (
                      <ListItem icon={<Icon icon="check" color="var(--primary-color)" />}>
                        {children}
                      </ListItem>
                    ),
                  }}
                >
                  <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
                </MDXProvider>
              </Content>
              {!flags.alternative && ctas.length && (
                <Footer>
                  {ctas.map((cta, i) => (
                    <Button to={cta.url} aria-label={cta.a11yText} key={i}>
                      {cta.text}
                    </Button>
                  ))}
                </Footer>
              )}
            </Container>
          );
        })}
      </Grid>
      {flags.alternative &&
        ctas.map((cta, i) => (
          <Button to={cta.url} aria-label={cta.a11yText} key={i}>
            {cta.text}
          </Button>
        ))}
    </Section>
  );
}
